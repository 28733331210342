@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

/* Chrome, Safari, and Opera */
::-webkit-scrollbar {
  width: 0;
  border-radius: 8px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
}

/* Firefox */
/* For Firefox 64+ */
* {
  scrollbar-color: transparent transparent;
  scrollbar-width: thin;
}

/* For Firefox 63 and below */
* {
  scrollbar-color: transparent transparent;
}

/* Microsoft Edge and IE */
* {
  -ms-overflow-style: none; /* Hide the scrollbar in IE and Edge */
}

*::-webkit-scrollbar {
  width: 0;
  border-radius: 0;
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.hovered_image {
  display: none;
}
.parent_nav:hover .child_nav {
  color: #ee383a;
  border-color: #ee383a;
}

.parent_nav:hover .hovered_image {
  display: flex;
}

.parent_nav:hover .initial_image {
  display: none;
}
.mirrored-icon {
  animation: rotateInfinity 2s linear infinite;
}

@keyframes rotateInfinity {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

.react-calendar {
  width: 100%;
  max-width: 100%;
  background-color: #fff;
  color: #222;
  border-radius: 8px;
  font-size: 14px;
  padding: 5px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  line-height: 100%;
}
.react-calendar__navigation__prev2-button {
  display: none;
}
.react-calendar__navigation {
  display: flex;
}
.react-calendar__navigation__prev-button {
  font-weight: bold;
}
.react-calendar__navigation__next-button {
  font-weight: bold;
}
.react-calendar__month-view {
  padding-bottom: 20px;
}
.react-calendar__month-view__weekdays {
  padding: 20px 0 15px 20px;
  text-transform: capitalize;
  color: #828282;
}
abbr[title] {
  text-decoration: unset !important;
}
.react-calendar__navigation__next2-button {
  display: none;
}
.react-calendar__navigation__label {
  font-weight: bold;
}
.react-calendar__navigation button {
  color: #333333;
  min-width: 44px;
  margin-top: 8px;
}
.react-calendar__tile {
  padding: 5px;
  margin-bottom: px;
  color: #000;
}

.react-calendar__tile--now {
  background: #f5f5f5;
  border-radius: 6px;
  font-weight: bold;
  color: gray;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  border-radius: 6px;
  font-weight: bold;
  color: #ee383a;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #f8f8fa;
}
.react-calendar__tile--active {
  background: #ee383a;
  border-radius: 4px;
  font-weight: 500;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #ee383a;
  color: white;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f8f8fa;
}
.react-calendar__tile--rangeStart {
  border-radius: 6px;
  background: #ee383a;
  color: white;
}
.react-calendar__tile--rangeEnd {
  border-radius: 6px;
  background: #ee383a;
  color: white;
}
.disabled-date {
  color: gray;
  opacity: 0.5;
  cursor: text;
}

.toast {
  animation: toast-line 5s ease infinite;
  background: var(--toast-background);
  height: 3px;
  width: 0%;
}

@keyframes toast-line {
  0% {
    width: 0%;
  }
  50% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}
